<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <polygon
      style="fill:#F64B4A;"
      points="335.211,368.038 332.454,395.167 320.548,512 191.448,512 179.554,395.167 176.795,368.038 
	"
    />
    <g>
      <polygon
        style="fill:#E84242;"
        points="218.062,368.038 176.795,368.038 179.554,395.167 191.448,512 221.076,512 	"
      />
      <polygon
        style="fill:#E84242;"
        points="335.211,368.038 332.454,395.167 179.554,395.167 176.795,368.038 	"
      />
    </g>
    <path
      style="fill:#82DCC7;"
      d="M314.775,244.676c-22.864,0-42.37,14.339-50.043,34.511V206.27c0-4.818-3.91-8.729-8.729-8.729
	c-4.83,0-8.729,3.91-8.729,8.729v72.917c-7.673-20.172-27.179-34.511-50.043-34.511l0,0l0,0c0,28.392,22.106,51.608,50.043,53.409
	v49.482c0,4.819,3.899,8.729,8.729,8.729c4.818,0,8.729-3.91,8.729-8.729v-49.482C292.669,296.284,314.775,273.068,314.775,244.676
	L314.775,244.676L314.775,244.676z"
    />
    <path
      style="fill:#74CBB4;"
      d="M264.733,206.271v44.586c-2.816,0.57-5.738,0.873-8.729,0.873c-2.991,0-5.912-0.303-8.729-0.873
	v-44.586c0-4.818,3.899-8.729,8.729-8.729C260.822,197.542,264.733,201.452,264.733,206.271z"
    />
    <path
      style="fill:#F2F2F2;"
      d="M373.136,117.136c0-16.884-9.62-31.506-23.674-38.715c4.837-15.036,1.298-32.18-10.635-44.113
	c-11.939-11.939-29.081-15.475-44.115-10.636C287.501,9.62,272.876,0,256.001,0c-16.883,0-31.504,9.62-38.714,23.671
	c-15.034-4.839-32.175-1.301-44.114,10.636C161.24,46.24,157.7,63.385,162.538,78.42c-14.053,7.209-23.674,21.831-23.674,38.715
	c0,16.876,9.62,31.501,23.673,38.713c-4.839,15.034-1.303,32.176,10.636,44.115c11.933,11.933,29.077,15.473,44.113,10.635
	c7.209,14.053,21.831,23.674,38.715,23.674c16.877,0,31.502-9.621,38.714-23.674c15.037,4.838,32.181,1.299,44.114-10.635
	c11.939-11.939,15.475-29.081,10.636-44.115C363.516,148.637,373.136,134.012,373.136,117.136z M279.644,174.218h-47.289
	l-33.439-33.439V93.492l33.439-33.439h47.289l33.439,33.439v47.289L279.644,174.218z"
    />
    <circle style="fill:#FED159;" cx="256.001" cy="117.138" r="62.503" />
    <polygon
      style="fill:#F6C454;"
      points="280.773,101.348 280.773,88.459 262.442,88.459 262.442,75.756 249.554,75.756 
	249.554,88.459 231.222,88.459 231.222,123.578 267.884,123.578 267.884,132.922 231.222,132.922 231.222,145.81 249.554,145.81 
	249.554,158.514 262.442,158.514 262.442,145.81 280.773,145.81 280.773,110.691 244.111,110.691 244.111,101.348 "
    />
    <polygon
      style="fill:#F64B4A;"
      points="354.709,376.541 157.291,376.541 162.529,319.569 349.471,319.569 "
    />
    <polygon
      style="fill:#E84242;"
      points="162.529,319.569 157.291,376.541 199.188,376.541 198.606,319.569 "
    />
    <path
      style="fill:#F6C454;"
      d="M255.998,184.059c-36.902,0-66.926-30.023-66.926-66.926s30.023-66.924,66.926-66.924
	s66.924,30.022,66.924,66.924S292.901,184.059,255.998,184.059z M255.998,65.081c-28.703,0-52.054,23.352-52.054,52.053
	s23.352,52.054,52.054,52.054s52.053-23.352,52.053-52.054S284.701,65.081,255.998,65.081z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
